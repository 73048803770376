import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["stars", "score"]

  connect() {
    this.scoreTarget.classList.add("hidden")
  }

  toggleStars() {
    this.starsTarget.classList.toggle("hidden")
    this.scoreTarget.classList.toggle("hidden")
  }

  toggleScore() {
    this.toggleStars()
  }
}
